/* src/components/header.css */

/* .header {
    background-color: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(5px);
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 1);
} */

.header {
    position: fixed; /* Fixes the header to the top */
    height: 110px;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Ensures the header stays above other elements */
    background-color: rgba(70, 70, 70, 0.3);
    backdrop-filter: blur(5px);
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 1); /* Adjusted shadow for better visibility */
}

/* .header a {
    float: left;
    color: #c2c2c2;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    line-height: 25px;
    border-radius: 4px;
} */

.header li, .logo {
    float: left;
    color: #c2c2c2;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    line-height: 25px;
    border-radius: 4px;
}

.header li:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
}

.header a.active, .header li.active {
    text-decoration: underline;
    color: white;
}

.h-title {
    text-align: center;
    background: linear-gradient(to right, #6ee1f5, #6457f0);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.header-left {
    margin-right: auto;
}

.header-right {
    margin-left: auto;
}

/* Mobile-specific styles */
@media screen and (max-width: 1050px) {
    .header {
        padding: 0px 10px;
    }

    .header a {
        float: none;
        display: block;
        text-align: left;
    }

    .logo {
        height: 5.8rem;
    }

    .h-title {
        margin-right: 2rem;
    }
}

/* Styles for larger screens if needed */
@media screen and (min-width: 1051px) {
    .header-right {
        width: 19.9rem;
    }

    .header-left {
        width: 19.9rem;
    }

    .logo {
        height: 7rem;
    }
}

.h-logo {
    height: 100%;
}