/* src/App.css */
.app {
  font-family: 'Share Tech Mono','Arial', sans-serif;
  color: #e0e0e0;
}

.app-body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #303030; /* Optional: Set a background color for the entire page */
}

.main-content {
  flex: 1; /* Takes up remaining vertical space */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: auto; /* Allow content to scroll if it exceeds the viewport height */
}

.center-section {
  text-align: center;
  padding: 20px; /* Adjust the padding as needed */
  background-color: rgba(0, 0, 0, .65);
  box-shadow: 20px 0 20px -10px rgba(0, 0, 0, 1), -20px 0 20px -10px rgba(0, 0, 0, 1);
  max-width: 80%;
  margin-top: 108px;
  /* Adjust the box shadow as needed */
}

.hr {
  border: 0;
  height: 3px;
  background-color: #bbe1f5;
}

/* Mobile-specific styles */
@media screen and (max-width: 600px) {
  .center-section {
    max-width: 90%;
  }
}
